import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import MyTable from "../pages/Coupons";
// import CouponFormComponent from "../pages/Coupons/CouponFormComponent";
// import CouponForm from "pages/CouponFrom";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import TagGeneration from "../pages/Taggeneration/index";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import ContactsList from "../pages/Users/index";
import TagsList from "../pages/Tags/index";
import Macpairing from "pages/Macpairing/Macpairing";
import Managereturn from "pages/managereturn/Managereturn";
import Returnproducts from "pages/returnproducts/Returnproducts";
import Insurancedetails from "pages/insurancedetails/Insurancedetails";
import CreateAdmins from "pages/Admins";
import Dispatch from "pages/Dispatchmodule/Dispatch";
import Visualization from "../pages/Visualization/index.js";

const authProtectedRoutes = [
  { path: "/macpairing", component: Macpairing },
  { path: "/returnproducts", component: Returnproducts },
  { path: "/managereturn", component: Managereturn },
  { path: "/dashboard", component: Dashboard },
  { path: "/lostandfound", component: Insurancedetails },

  //profile
  { path: "/profile", component: UserProfile },

  //profile
  { path: "/user-list", component: ContactsList },

  //tags
  { path: "/tags-list", component: TagsList },
  { path: "/coupon-list", component: MyTable },

  { path: "/taggeneration-list", component: TagGeneration },

  // admins
  { path: "/admin-list", component: CreateAdmins },
  {path : "/dispatch",component :Dispatch },

      //Visualization
      {path:"/visualization",component:Visualization},

  // { path: "/coupon-form", component: CouponForm },
  // { path: "/coupon-form", component: CouponFormComponent },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
