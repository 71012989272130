import * as React from "react";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { YAxis } from "recharts";
import { height } from "@mui/system";
import axiosInstance from "Axiosinstance";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { ThemeProvider, createTheme } from "@mui/system";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IndeterminateCheckBox } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

async function getData(year) {
  const { data } = await axiosInstance.get(
    `api/crm/getTagActivated?year=${year}`
  );
  return data;
}

async function getTagsCountCurrentYear() {
  const { data } = await axiosInstance.post(`/api/crm/gettagscountcurrentyear`);
  return data;
}
const chartSetting = {
  yAxis: [
    {
      label: "Tag Activation",
    },
  ],
  width: 700,
  height: 300,
  // sx: {
  //     [`.${axisClasses.left} .${axisClasses.label}`]: {
  //         transform: 'translate(-20px, 0)',
  //     },
  // }
};
const Visualization = () => {
  //meta title
  document.title = "Data Visualization";

  const [data, setData] = useState([]);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [tagsCount, setTagsCount] = React.useState({
    tagActivatedToday: 0,
    tagActivatedThisYear: 0,
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const resData = await getTagsCountCurrentYear();
        // console.log("resData: ", resData);
        setTagsCount({
          tagActivatedToday: resData.totalTagsToday,
          tagActivatedThisYear: resData.totalTagsThisYear,
        });
      } catch (e) {
        console.log(
          "Error::getTagsCountCurrentYear: Error while fetching the activated tags count!"
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const resData = await getData(year);
        // console.log(resData);
        setData(resData.data);
        setTableData(resData.productCategoryWiseTagsCount);
      } catch (e) {
        console.log(
          "Error::getData: Error while fetching the year wise activated tags count!"
        );
      }
    })();
  }, [year]);
  const handleChange = (event) => {
    console.log(event.target.value);
    setYear(event.target.value);
  };
  return (
    <Box sx={{ flexGrow: 1, mt: 15, ml: 5 }}>
      <Grid container spacing={4}>
        {/* Card Grid */}
        <Grid xs={4}>
          <Card sx={{ maxWidth: 200, backgroundColor: "#ffea00" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Total Activations Today
              </Typography>
              <Typography variant="h5" component="div">
                {tagsCount.tagActivatedToday}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={4}>
          <Card sx={{ maxWidth: 200, backgroundColor: "#ff9100" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Activations Current Year
              </Typography>
              <Typography variant="h5" component="div">
                {tagsCount.tagActivatedThisYear}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Select Grid */}
        <Grid xs={4}></Grid>
        <Grid xs={8}>
          <Typography sx={{ fontSize: 16 }} color="black" gutterBottom>
            The data shown in the Bar Graph (A) and Table (A) below are based on
            the selected year.
          </Typography>
          <Item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select year of Tag Activation
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Select year of Tag Activation"
                onChange={handleChange}
              >
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
            </FormControl>
          </Item>
        </Grid>

        {/* Bar Grid */}
        <Grid xs={4}></Grid>
        <Grid xs={8}>
          <Item>
            <BarChart
              series={[
                { data: data },
                // { data: [51, 6, 49, 30] },
                // { data: [15, 25, 30, 50] },
                // { data: [60, 50, 15, 25] },
              ]}
              height={290}
              xAxis={[
                {
                  data: [
                    "JAN",
                    "FEB",
                    "MAR",
                    "APR",
                    "MAY",
                    "JUN",
                    "JUL",
                    "AUG",
                    "SEP",
                    "OCT",
                    "NOV",
                    "DEC",
                  ],
                  scaleType: "band",
                },
              ]}
              margin={{ top: 5, bottom: 30, left: 40, right: 10 }}
              {...chartSetting}
            />
          </Item>
          <Typography
            align="center"
            sx={{ fontSize: 16 }}
            color="black"
            gutterBottom
          >
            Bar Graph (A)
          </Typography>
        </Grid>

        {/* Bar Grid */}
        <Grid xs={4}></Grid>
        <Grid xs={8} style={{ marginBottom: "5rem" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Product Category Details
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    No of Activatoin
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {data._id}
                    </TableCell>
                    <TableCell align="right">{data.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            align="center"
            sx={{ fontSize: 16 }}
            color="black"
            gutterBottom
          >
            Table (A)
          </Typography>
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>
    </Box>
  );
};

export default Visualization;
